import React from 'react';
import { Routes, Route, Link } from "react-router-dom";
import '../css/AppFooter.css';

function AppFooter() {
	return (
		<div className="App-footer-container">
			<div className="App-footer-link-container">
				<nav> <Link className="App-footer-link" to="/" > Home </Link> </nav>
				<div className="App-footer-link"> • </div>
				<nav> <Link className="App-footer-link" to="/privacy" > Privacy Policy </Link> </nav>
				<div className="App-footer-link"> • </div>
				<a className="App-footer-link" href="mailto:admin@verbosity.io?subject=Email%20Support%20-%20Verbosity"> Email Me </a>
			</div>
			<div className="App-footer-credit-line">
				© 2022 Yifan Huang. All rights reserved. Apple, the Apple logo, iPhone, iPad, and Apple Watch are trademarks of Apple Inc.,
				registered in the U.S. and other countries. App Store is a service mark of Apple Inc.
			</div>
		</div>
	);
}

export default AppFooter;