import React from 'react';
import './css/App.css';
import { Route, Link } from "react-router-dom";
import AppHeader from './components/AppHeader';
import FeatureColumn from './components/FeatureColumn';
import AppFooter from "./components/AppFooter";
import NavBar from "./components/NavBar"

function App() {
  return (
    <div className="App">
      <NavBar />
      <AppHeader />
      <FeatureColumn />
      <AppFooter />
    </div>
  );
}

export default App;
