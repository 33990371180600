import React from 'react';
import '../css/NavBar.css';
import logo from '../asset/logo_purple.png';
import {Link} from "react-router-dom";

function NavBar() {
	return (
		<div className="Nav-item-container">
			<nav>
				<Link to="/" >
					<img src={logo} className="Nav-item-logo" alt="home-logo" />
				</Link>
			</nav>
			<hr className="Nav-thematic-break"/>
		</div>
	);
}

export default NavBar;
