import React from 'react';
import '../css/AppHeader.css';
import logo from '../asset/logo_purple.png';
import downloadAtAppStore from '../asset/Download_on_the_App_Store_Badge.svg'

function AppHeader() {
    return (
        <div className="App-header-container">
            <img src={logo} className="App-logo" alt="logo" />
            <h1 className="App-header-title"> Verbosity </h1>
            <p className="App-header-summary-text">
              A simple yet effective iOS app to help you build vocabularies and ace exams.
            </p>
            <a href="https://apps.apple.com/app/verbosity-vocab-builder/id1622322727">
              <img src={downloadAtAppStore} className="Download-on-app-store-icon" alt="download-on-app-store" />
            </a>
            <a className="App-header-ios-requirement" href="https://www.apple.com/ios/ios-15">
                Requires iOS 15.0 or higher.
            </a>
        </div>
    );
}

export default AppHeader;