import React from 'react';
// import {SM2Algo, Built_In_List, Dictionary, Free} from "../const/ConstData";
import '../css/FeatureColumn.css';

function FeatureColumn() {
	return (
		<div className="Feature-container">
			<div className="Feature-row">
				<div className="Feature-column">

					<div className="Feature-cell-container">
						<p className="Feature-cell-title">
							Build Your Vocabulary
						</p>
						<p className="Feature-cell-body">
							Verbosity utilizes SM-2, a <a href="https://en.wikipedia.org/wiki/Spaced_repetition"> spaced repetition </a>
							algorithm, to optimize your learning and help you build your vocabularies more efficiently and effectively.
						</p>
					</div>
				</div>

				<div className="Feature-column">
					<div className="Feature-cell-container">
						<p className="Feature-cell-title">
							Trustable Dictionary
						</p>
						<p className="Feature-cell-body">
							Even if you don't need to study for any test, Verbosity can be used as a dictionary app.
							You can get reliable and accurate definitions of any English word.
						</p>
					</div>
				</div>
			</div>

			<div className="Feature-row">
				<div className="Feature-column">
					<div className="Feature-cell-container">
						<p className="Feature-cell-title">
							Built-in Word Lists
						</p>
						<p className="Feature-cell-body">
							When preparing for standardized language test, like <a href="https://www.ets.org/toefl">TOEFL</a> and <a href="https://satsuite.collegeboard.org/sat">SAT</a>, knowing right vocabularies is vital.
							<hr className="Feature-line-break"/>
							Verbosity provides four (more to come!) built-in word lists for anyone who are interested in building vocabularies
							in preparation for tests.
						</p>
					</div>
				</div>

				<div className="Feature-column">
					<div className="Feature-cell-container">
						<p className="Feature-cell-title">
							Free and Independent
						</p>
						<p className="Feature-cell-body">
							Verbosity is created by <a href="https://www.linkedin.com/in/yifan-huang-3b5b90196/">me</a>, a student at UC Davis.
							Verbosity is my personal project and it is self-funded.
							<hr className="Feature-line-break"/>
							It is completely free and remain to be free in future.
							You can contact me through my LinkedIn page or <a href="mailto:admin@verbosity.io?subject=Email%20Support%20-%20Verbosity">this email</a>.
						</p>
					</div>
				</div>
			</div>
		</div>
	);
}

export default FeatureColumn;